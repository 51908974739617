import React from "react"
import Layout from "../../components/layout"
import "../index.css"
import Hero from "../../components/hero"
import WorksHome from "../../components/worksHome"
import Services from "../../components/services"
import Testimonials from "../../components/testimonials"
import Contact from "../../components/contact"

export default () => (
  <Layout
    lang="fr"
    pageUrl=""
    seoTitle="Création: sites web, apps, digital marketing - Agence Web Suisse"
    seoDescription="Webpanda est une agence suisse spécialisée dans le digital. Travaillons ensemble sur votre prochain site web, application ou stratégie digitale!"
  >
    <div id="hero-background">
      <Hero lang="fr" />
    </div>
    <div id="works-background">
      <WorksHome lang="fr" />
    </div>
    <div id="services-background">
      <Services lang="fr" />
    </div>
    <div id="testimonials-background">
      <Testimonials lang="fr" />
    </div>
    <div id="contact-background">
      <Contact lang="fr" />
    </div>
  </Layout>
)
